<template>
  <content-loader
    :height="230"
    :width="400"
    :speed="3"
    :primary-color="primaryColor"
    :secondary-color="secondaryColor"
  >
    <rect x="0" y="0" rx="3" ry="3" width="100" height="8" />
    <circle cx="40.54018802146351" cy="45.0301880214635" r="21.540188021463507" />
    <rect x="86" y="29" rx="3" ry="3" width="100" height="8" />
    <rect x="86" y="46" rx="3" ry="3" width="100" height="8" />
    <rect x="19" y="84" rx="3" ry="3" width="40" height="8" />
    <rect x="85" y="84" rx="3" ry="3" width="100" height="8" />
    <rect x="19" y="134" rx="3" ry="3" width="40" height="16" />
    <rect x="70" y="134" rx="3" ry="3" width="40" height="16" />
    <rect x="120" y="134" rx="3" ry="3" width="40" height="16" />
    <rect x="190" y="134" rx="3" ry="3" width="40" height="16" />
    <rect x="240" y="134" rx="3" ry="3" width="40" height="16" />
    <rect x="290" y="134" rx="3" ry="3" width="40" height="16" />
    <circle cx="23.547511554864492" cy="212.77751155486447" r="10.547511554864494" />
    <rect x="40" y="208" rx="3" ry="3" width="40" height="8" />
  </content-loader>
</template>
<script>
import { ContentLoader } from 'vue-content-loader'
import Placeholder from './Placeholder'

export default {
  components: {
    ContentLoader
  },
  extends: Placeholder
}
</script>
